import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConsoleAPI from '../../api/ConsoleAPI';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

function PaymentFailedPage({ navigation }) {

  return (
    <div>

      <Header />

      <main id="main-content">
        <div className="owl-carousel header-slider">
          <div className="slide main-bg"></div>
        </div>

        <div className="row">
          <div className="small-12">
            <div className="content-frame">
              <div className="header-content">
                <h2 className="text-center">Your payment failed</h2>
                <p className="text-center intro-title">Sorry, something went wrong and your payment failed, please try again.</p>
             
                <p className="text-center intro-title">For any issues, please contact our Gifting Account Support team at <a href="mailto:afb.giftcards@sainsburys.co.uk">afb.giftcards@sainsburys.co.uk</a>.</p>

              </div>
            </div>
          </div>
        </div>

      </main>

      <Footer />

    </div>
  )

}

export default PaymentFailedPage;
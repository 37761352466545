import React from 'react';
import { Switch, Route } from "react-router-dom";
import ConfirmationPage from '../containers/Confirmation';
import OrderPage from '../containers/Order';
import UnsubscribePage from '../containers/Unsubscribe';
import DiscountsPage from '../containers/Discounts';
import PaymentCompletePage from '../containers/Complete';
import PaymentFailedPage from '../containers/Failed';

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={OrderPage} />
                <Route exact path="/confirmation/:id" component={ConfirmationPage} />
                <Route exact path="/failed" component={PaymentFailedPage} />
                <Route exact path="/complete/:id" component={PaymentCompletePage} />
                <Route exact path="/unsubscribe" component={UnsubscribePage} />
                <Route exact path="/discounts" component={DiscountsPage} />
            </Switch>
        )
    }
};

export default Routes;
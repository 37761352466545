import React from 'react';

class Header extends React.Component {

    render() {
        return (
            <header>
                <div className="skip-content">
                    <a id="skipToNav" href="#top-nav" className="skip">Skip to navigation</a>
                    <a id="skipToSearch" href="#site-search" className="skip">Skip to search</a>
                    <a id="skipToMain" href="#main-content" className="skip">Skip to content</a>
                </div>
                <div className="desktop">
                    <nav>
                        <div className="top-nav" id="top-nav">
                            <div className="row">
                                <div className="small-12 text-center">
                                    <a href="https://sainsburysforbusiness.co.uk">
                                        <img src="/assets/SainsburysForBusiness_Logo_Orange_CMYK_UC2897248_20240711.png" alt="Sainsburys for Business" draggable="false" style={{ width: 325, height: 'auto' }} />
                                        {/*<img src="/assets/img/logobanner.png" alt="Argos for Business Logo" draggable="false" />*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="primary-nav">
                            <div className="row">
                                <div className="small-12">
                                    <ul id="menu-main-menu" className="dropdown menu desktop-menu">
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/gift-cards/">Gift Cards</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/multi-brand/">Multi Brand</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/why-choose-us/">Why choose us?</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/how-to-buy/">How to Buy</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/regular-gifting/">Regular Gifting</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://sainsburysforbusiness.co.uk/contact/">Contact</a></li>
                                        <li id="menu-item-205" className="menu-item menu-item-type-post_type menu-item-object-page order-now"><a href="/">Order Now</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </nav>
                </div>
                <div className="mobile">
                    <div className="mobile__inner">
                        <div className="row">
                            <div className="small-2">
                                <button id="burger"></button>
                            </div>
                            <div className="small-8 mobile-logo">
                                <a href="https://sainsburysforbusiness.co.uk">
                                    <img src="/assets/SainsburysForBusiness_Logo_Orange_CMYK_UC2897248_20240711.png" alt="Sainsburys for Business" draggable="false" />
                                </a>
                            </div>
                            <div className="small-2">
                                &nbsp;
                            </div>
                        </div>
                        <div className="row mob-nav">
                            <ul id="menu-main-menu" className="dropdown menu desktop-menu" data-dropdown-menu>
                                <li id="menu-item-138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-138"><a href="https://argosforbusiness.co.uk/business-purchasing/">Business Purchasing</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </header>
        )
    };

}

export default Header;
